import logo from './logo.svg';
import './App.css';

import React, { useEffect } from 'react';
import { Button } from '@mui/material';

const apiUrl = process.env.REACT_APP_BASE_URL;

function App() {
  useEffect(() => {
    const url = apiUrl + '/api/ping/';

    // Make the GET request using fetch
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Output the result to the console
        console.log('API Response:', data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Welcome to My Material-UI App!</h1>
      {/* Your other components and UI elements */}
    </div>
  );
}

export default App;
